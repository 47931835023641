<template>
	<div>
		<DataGrid :config="dataGridConfig" :data="facturas">
			<div class="col-md-3">
				<button class="btn warning-btn" @click="obtener_facturas">Recargar</button>
			</div>
		</DataGrid>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import axios from '@/plugins/axios'
	import utils from '@/helpers/utilidades.js'

	export default {
		components: {
			DataGrid
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					receptor_nombre: 'Nombre receptor'
					,uuid: 'UUID'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,serie: 'Serie'
					,folio: 'Folio'
					,estatus: 'Estatus'
				}
				,filters: {
					cols: {
						id: 'ID'
						,uuid: 'UUID'
						,total: 'Total'
						,subtotal: 'Subtotal'
						,serie: 'Serie'
						,folio: 'Folio'
						,estatus: {
							text: 'Estatus'
							,options: {
								0: 'Inactivo'
								,1: 'Activo'
							}
						}
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					proveedor: function(val) {
						return val.nombre;
					}
					,estatus: function(val) {
						switch(parseInt(val)) {
							case 0:
								return 'Inactivo';
								break;
							case 1:
								return 'Activo';
								break;
						}
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
		})
		,created: function() {
			this.obtener_facturas();
		}
		,methods: {
			obtener_facturas: function() {
				axios.get(utils.url_options('http://bpbbsfacturacion-env-test.3xpr6zm5m4.us-east-1.elasticbeanstalk.com/api/v1/factura', this.options))
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error al cargar facturas');
				})
			}
		}
	}
</script>